<template>
  <div class="content programs">
    <h1>Manage Programs</h1>
    <ul
      v-if="userData.programs && userData.programs.length"
      class="list-group"
    >
      <li
        v-for="program in userData.programs"
        :key="program.id"
        class="row my-4 py-3 card justify-content-between"
      >
        <!-- title -->
        <div class="col-12">
          <h5 class="card-title">
            {{ program.title }}
            <br>
            <small>Started {{ date(program.created) }}</small>
            <p
              class="text-uppercase h5 text-primary font-weight-bold mt-4"
              :class="{
                'text-danger': ['cancelled', 'end_of_access'].includes(program.status),
                'text-warning': program.status === 'no_payment',
              }"
            >
              {{ program.status }}
            </p>
          </h5>
        </div>

        <div class="mt-3" />


        <!-- payment method -->
        <div class="col-12 my-auto text-center">
          <router-link
            v-if="program.payment_method"
            class="text-dark font-weight-bolder"
            :to="{ name: 'update_payment_method', params: { id: program.id } }"
          >
            Update Payment Method
          </router-link>
        </div>

        <div class="mt-3" />

        <!-- cancel -->
        <div class="col-12 text-right my-auto">
          <b-button
            v-if="program.status === 'active'"
            class="btn btn-danger btn-block"
            :to="{ name: 'program_cancel', params: { id: program.id } }"
          >
            Cancel
          </b-button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import CoursesMixin from '@/mixins/Courses.vue';

export default {
  name: 'ManagePrograms',
  metaInfo: {
    title: 'Manage Programs',
  },
  mixins: [CoursesMixin],
  data() {
    return {
      stripeScriptStatus: 'loading',
      update_payment_method_id: 0,
      stripe: null,
      card: null,
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  mounted() {
    this.fetchCourses();
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.programs {
  max-width: 500px;
}
</style>
