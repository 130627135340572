<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async fetchCourses() {
      this.loading = true;

      const url = `${process.env.VUE_APP_API_URL}users/${this.userData.id}/enrollments/`;

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.loading = false;

      if (response && response.status === 200) {
        const responseJson = await response.json();
        const newUserData = this.userData;
        newUserData.programs = responseJson;
        this.$store.commit('userData', newUserData);
      } else {
        this.error = true;
      }
    },
  },
};
</script>
